import React from "react"
import { Flex, Tag, Typography } from "antd";
import { AppDivider } from "../../../components";

const PpcColaboratorData = ({colaborator, job, modelPPC, toPayment, status, monthAndYear }) => 
{
    return (
        <>
            <AppDivider light>Dados do Colaborador</AppDivider>  
            
            <Flex align="start" justify="space-between" style={{paddingTop: 0, padding: '8px'}}>
                <Flex align="start" vertical justify="start">
                    {monthAndYear && <Typography align="start"><strong>Mês Ano:</strong> { monthAndYear }</Typography>}                    
                    <Typography align="start"><strong>Modelo de Avaliação:</strong> { modelPPC.name }</Typography>
                    <Typography align="start"><strong>Cargo do mês:</strong> { job.name }</Typography>
                </Flex>
                <Flex align="start" vertical justify="start">                    
                    <Typography align="start"><strong>Colaborador:</strong> { colaborator.name }</Typography> 
                    <Typography align="start"><strong>% da Avaliação:</strong> { job.percentagePPC }</Typography>                                   
                    <Typography align="start"><strong>% do PCP:</strong> { job.percentageTasks }</Typography>
                </Flex>  
                <Flex align="start" vertical justify="start">                    
                    <Typography align="start"><strong>Documento:</strong> { colaborator.document }</Typography>
                    { toPayment &&<Typography align="start"><strong>Salário Base:</strong> R$ { job.salary }</Typography>}
                </Flex>
                <Flex align="start" vertical justify="start">
                { status >= 0 && 
                        <Typography align="start"><strong>Status do PPC:</strong> 
                            <Tag color={ status === 0 ? 'blue' : status === 1 ? 'green' : status === 2 ? 'orange' : 'green' }>
                                { status === 0 ? 'Aberto' : status === 1 ? 'Aprovado' : status === 2 ? 'Aprovação Pendente' : 'Reprovado' }                        
                            </Tag>
                        </Typography> }
                    { toPayment &&<Typography align="start"><strong>Premiação Base:</strong> R$ { job.salaryBonus }</Typography>}
                </Flex>
            </Flex>
        </>
    )
}

export default PpcColaboratorData;