import { http } from '.';

const PPCEvaluationService = { 
    getByMonthAndYear : (colaboratorId, monthAndYear) => http.get(`ppc-evaluation/${colaboratorId}/by-month-and-year?monthAndYear=${monthAndYear}`),   
    check: (colaboratorId, model) => http.patch(`ppc-evaluation/${colaboratorId}/check`, model),
    delete: (colaboratorId, date) => http.delete(`ppc-evaluation/${colaboratorId}/date/${date.toISOString().split('T')[0]}`),
    create: (colaboratorId, model) => http.post(`ppc-evaluation/${colaboratorId}`, model)
}

export default PPCEvaluationService;
