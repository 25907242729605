import React, { useContext, useEffect, useState } from "react"
import { AppDivider, EmptyState, Loading, PageHeader } from '../../components';
import { Collapse, Flex, Tag, Typography } from "antd";
import { ReportServce } from "../../services";
import { useSearchParams } from "react-router-dom";
import { MonthAndYearHelper } from "../../helpers";
import { PpcColaboratorTasks, ReportHeader } from "./components";
import "./components/report.css"
import { MessageContext } from "../../contexts/MessageContext";

const PpcTaskReportPage = () => 
{
    const [ loading, setLoading ] = useState (false);
    const [ data, setData ] = useState (null);
    const [ searchParams ] = useSearchParams();
    const { showError } = useContext(MessageContext);

    const loadData = async () => {

        try 
        {
            setLoading(true);  
            const filters = searchParams.get('params');
            const filterObject = JSON.parse(filters); 
            const { data } = await ReportServce.tasks({
                ...filterObject,
                monthAndYearStart : MonthAndYearHelper.toMonthAndYear(filterObject.startDate),
                monthAndYearEnd: MonthAndYearHelper.toMonthAndYear(filterObject.endDate)
            });
            setData(data.value);
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para carregar os dados do relatório');
        }
        finally{
           setLoading(false)
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <PageHeader>
                <ReportHeader 
                    name="Plano de Curto Prazo"
                    subTitle="Relatório de gerenciamento de plano de curto prazo"
                ></ReportHeader>
            </PageHeader>
            {
                loading ? <Loading/>
                :
                <>
                    {   
                        data ? 
                        <>
                            {
                                data.colaboratorsTasks.map(_ => {
                                    return (
                                        <Collapse
                                            key={_.colaborator.id}
                                            expandIconPosition="end"
                                            style={{marginBottom: '8px'}}
                                            collapsible="header"
                                            size="small"
                                            defaultActiveKey={[ _.colaborator.id ]}
                                            items={[
                                                {
                                                    key: _.colaborator.id,
                                                    label: _.colaborator.name,
                                                    children: <PpcColaboratorTasks 
                                                                colaboratorId={_.colaborator.id} 
                                                                monthAndYearTasks={_.monthAndYearTasks}>
                                                            </PpcColaboratorTasks>
                                                }
                                            ]}
                                        >                          
                                        </Collapse>)
                                })
                            }     
                            <AppDivider light>Resumo</AppDivider>
                            <Flex align="flex-start" justify="space-between" style={{paddingTop: 0, padding: '8px'}}>
                                <Typography.Text type="default" align="start"><strong>Completadas: </strong> {data.summary.totalDone}</Typography.Text>
                                <Typography.Text type="default" align="start"><strong>Não Completadas: </strong> {data.summary.totalUndone}</Typography.Text>
                                <Typography.Text type="default" align="start"><strong>Total: </strong> {data.summary.total}</Typography.Text>
                                <Typography.Text type="default" align="start"><strong>% Atingindo: </strong>                         
                                    <Tag color={data.summary.average > 50 ? 'green' : 'red'} >{data.summary.average}</Tag>
                                </Typography.Text>                                      
                            </Flex>   
                        </> 
                        : <EmptyState/>
                    }
                </>
            }            
        </>
    )
}

export default PpcTaskReportPage;