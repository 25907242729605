import React, { useContext, useState } from "react"
import { Modal, Form, message, Row } from 'antd';
import { AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { JobSelect } from "../job/components";
import { ColaboratorService } from "../../services";
import { MessageContext } from "../../contexts/MessageContext";

const ColaboratorFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? 
                    await ColaboratorService.update(currentRecord.id, values) : 
                    await ColaboratorService.create({
                        name : values.name,
                        document :values.document,
                        jobId : values.jobId
                    });              
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) 
            {
                showError(error, 'Ocorreu um problema para realizar operação');
                console.log(error);    
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Colaborador" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading : loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_colaborator"
                    initialValues={
                        currentRecord ? 
                        { name : currentRecord.name, document: currentRecord.document, status : currentRecord.status, jobId: currentRecord.job.id } : 
                        { name : '', document: '', status : 1, jobId: null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput placeholder="Informe o nome do colaborador"/>
                        </AppFormItem>
                        <AppFormItem
                            span={12}
                            name="document"
                            label="Documento" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Informe o documento"/>
                        </AppFormItem>
                        <AppFormItem
                            span={12}
                            name="jobId"
                            label="Cargo" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <JobSelect placeholder="Selecione o cargo" currentRecord={currentRecord?.job}></JobSelect>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>           
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default ColaboratorFormModal;