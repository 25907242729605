import { Flex, Spin } from "antd"

const Loading = () =>
{
    return(
        <Flex justify="center">
            <Spin size="large" ></Spin>
        </Flex> 
    )
}

export default Loading;