import React, { useContext, useEffect, useState } from "react";
import { message, Select } from "antd";
import { debounce } from "lodash";
import { MessageContext } from "../contexts/MessageContext";

const PaginatedSelect = ({apiCallback, currentRecord, statusFilter, ...rest}) => {
  const [ search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(false);  
  const { showError } = useContext(MessageContext);

  const fetchData = debounce(async (currentPage, search, isFilter) => {
    try 
    { 
      setLoading(true);   
      const { data } = await apiCallback({ search, status: statusFilter}, currentPage, 10);

      let newOptions = [];
      if (isFilter)
        newOptions = [ ...data.value.values.map(_ => ({ value : _.id, label: _.name}))];
      else
        newOptions = [...options,  ...data.value.values.map(_ => ({ value : _.id, label: _.name}))];

      if (currentRecord && ! isFilter)
      {
        newOptions = [ ...newOptions.filter(_ => _.value !== currentRecord.id)];
        newOptions = [ {value : currentRecord.id, label : currentRecord.label}, ...newOptions];
      }

      setOptions(newOptions);          
      if (currentPage >= data.value.totalOfPages) setHasMore(false);
    } 
    catch(error) 
    {
      showError(error, 'Ocorreu um problema para consultar os registros');       
    }
    finally{
       setLoading(false)
    }
  }, 300);

  const handlePopupScroll = debounce((pEvent) => {
    const { scrollTop, scrollHeight, clientHeight } = pEvent.target;

    if (!loading && hasMore)
    {
      if (scrollTop + clientHeight >= scrollHeight - 10) 
      {
        const nextPage = page + 1;
        fetchData(nextPage, search, false);
        setPage(nextPage);
      }
    }
  }, 300);

  useEffect(() => 
    {
      if (! show)
      {
        fetchData(1, "", false);
        setShow(false);
      }
    }, [show]);

  return (
    <Select
      allowClear
      showSearch
      onSearch={(value) => {       
        setPage(1);
        setSearch(value);
        fetchData(1, value, true);
      }}
      loading={loading}
      filterOption={() => true}
      style={{ 
        maxHeight: 300
      }}     
      onPopupScroll={(e) => handlePopupScroll(e)}
      value={currentRecord ? { value : currentRecord.id, label : currentRecord.label } : null}
      size="small"
      {...rest}
    >
      {options.length && options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaginatedSelect;
