import * as dayjs from 'dayjs'

export default {
    toDayJs(monthAndYear)
    {
        const monthAndYearSplited = monthAndYear.split("-");
        const _date = new Date(monthAndYearSplited[1], Number.parseInt(monthAndYearSplited[0]) - 1, 1);
        return dayjs(_date);
    },
    toMonthAndYear(date)
    {        
        const splittedDate = date.split("-");          
        return `${splittedDate[1]}-${splittedDate[0]}`;
    },
    datePickerToMonthAndYear(datePicker)
    {
        const date = datePicker.toDate().toISOString();        
        const splittedDate = date.split("-");          
        return `${splittedDate[1]}-${splittedDate[0]}`;
    }
}