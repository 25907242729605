import { http } from '.';

const PPCMonthService = {
    monthGenerate: (model) => http.post(`ppc-month`, model),
    getByMonthAndYear: (monthAndYear, colaboratorId)=> http.get(`ppc-month/${monthAndYear}/colaborator/${colaboratorId}`),
    get : (filter, page, size) => 
    {
        const params = new URLSearchParams({   
            search : filter.search ? filter.search : "",
            status : filter.status ? filter.status : "",
            page : page, 
            pageSize : size
            }).toString();

        return http.get(`ppc-month?${params}`)
    }, 
    sendToApprove : (model) => http.patch(`ppc-month/send-to-approve`, model),
    approve : (model) => http.patch(`ppc-month/approve`, model),
    disapprove : (model) => http.patch(`ppc-month/disapprove`, model)
};

export default PPCMonthService;

