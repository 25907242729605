import React, { useContext, useEffect } from "react"
import { AppButton, AppFormItem, AppInput } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { Checkbox, DatePicker, Form, Row } from "antd";
import { ColaboratorSelect } from "../colaborator/components";
import { FilterIcon } from "../../components/Icons";
import { AuthContext } from "../../contexts/AuthContext";

const PpcColaboratorProgressFilterReportPage = () => 
{
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const { isAdmin } = useContext(AuthContext);

    const [form] = Form.useForm();
    const onFilterHandler = () => form
        .validateFields()
        .then((values) => window.open(`${window.location.origin}/report/ppc-colaborator-progress?params=${JSON.stringify(values)}`, '_blank'))
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    useEffect(() => {
        setTitleAndSubTitle('Evolução do colaborador', 'Filtro do relatório de evolução do colaborador');
    }, []);

    return (
        <Form 
        form={form}
        layout="vertical"
        name="form_filter_ppc_colaborator_progress_report"
        initialValues={{ 
            colaboratorId : null,
            startDate : null,
            endDate : null,
            toPayment : isAdmin
        }}>
        <Row>

                <AppFormItem
                    span={16}
                    name="colaboratorId"
                    label="Colaborador"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <ColaboratorSelect placeholder="Selecione o colaborador"></ColaboratorSelect>
                </AppFormItem>

                <AppFormItem
                    span={4}
                    name="startDate"
                    label="P. inicial"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <DatePicker size="small" format="MM-YYYY" picker="month" style={{ width: "100%"}} placeholder="selecione ano e mês"/>
                </AppFormItem>

                <AppFormItem
                    span={4}
                    name="endDate"
                    label="P. final"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <DatePicker size="small" format="MM-YYYY" style={{ width: "100%"}} picker="month" placeholder="selecione ano e mês"/>
                </AppFormItem>
                {
                    isAdmin &&
                    <AppFormItem
                        span={24}
                        name="toPayment"
                        valuePropName="checked">
                        <Checkbox>
                            Calcular ganho de salário
                        </Checkbox>
                    </AppFormItem>
                }
                        
            </Row>   
            <AppButton onClick={onFilterHandler}><FilterIcon/> Filtrar</AppButton> 
        </Form>
    )
}

export default PpcColaboratorProgressFilterReportPage;