import React from "react";
import { UserService } from "../../../services";
import { PaginatedSelect } from "../../../components";

const UserSelect = ({currentRecord, ...rest}) => {

  return (
    <PaginatedSelect
    currentRecord={currentRecord ? {id : currentRecord.id, label : currentRecord.name} : null} 
        apiCallback={UserService.get} 
        statusFilter={1}
        {...rest}></PaginatedSelect>
  );
};

export default UserSelect;
