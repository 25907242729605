import React, {  useContext, useEffect, useState } from "react"
import { AppButton, PageHeader } from "../../../components";
import { PPCEvaluationService } from "../../../services";
import { Dropdown } from "antd";
import { CreateIcon, DeleteIcon, ManagementIcon } from "../../../components/Icons";
import { PpcEvaluationDeleteDateFormModal, PpcEvaluationAddNewDateFormModal, PpcEvaluationTable } from "./";
import { MessageContext } from "../../../contexts/MessageContext";

const PpcEvaluationForm = ({colaboratorId, modelPPCId, monthAndYear}) => 
{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ showAddNewDateModal, setshowAddNewDateModal ] = useState(false);
    const [ showDeleteDateModal, setShowDeleteDateModal ] = useState(false);
    const [ daysInMonth, setDaysInMonth] = useState(0); 
    const { showError } = useContext(MessageContext);

    const menuProps = {
        items : [
            {
                label: 'Adicionar Dia',
                key: '1',
                icon: <CreateIcon />,
            },
            {
                label: 'Remover Dia',
                key: '2',
                icon: <DeleteIcon />,
                danger : true
            }
        ],
        onClick: ({key}) => key === '1' ? setshowAddNewDateModal(true) : setShowDeleteDateModal(true)
    };

    const loadData = async() => {
        try 
        {
            setLoading(true);   
            const { data } = await PPCEvaluationService.getByMonthAndYear(colaboratorId, monthAndYear);
            setDaysInMonth(data.value.daysInTheMonth);            
            setData(data.value.items);
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para carregar as avalições');
        }
        finally{
           setLoading(false)
        }
    }

    const checkEvaluationHandler = async (day, done,  modelPpcItemId) => 
    {        
        try 
        {
            setLoading(true);   
            const month = Number.parseInt( monthAndYear.split("-")[0]) - 1;
            const year = monthAndYear.split("-")[1];
            const model = {
                date : new Date(year, month, day),
                done : done,
                modelPpcItemId : modelPpcItemId
            }
            await PPCEvaluationService.check(colaboratorId, model);
            loadData();
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para realizar operação');
        }
        finally{
           setLoading(false)
        }

    }
    useEffect(() => {
        if (monthAndYear && modelPPCId) loadData();
    }, [monthAndYear, modelPPCId]);

    return (
        <>
            <PageHeader>
                <Dropdown
                    menu={menuProps}
                    placement="bottomLeft"
                    trigger={['click']}
                    arrow
                >
                    <AppButton><ManagementIcon/>Gerenciar</AppButton>
                </Dropdown>
                
            </PageHeader>              

            <PpcEvaluationTable 
                data={data}
                loading={loading}
                daysInTheMonth={daysInMonth}
                checkHandler={checkEvaluationHandler}
            ></PpcEvaluationTable>

            {
                showAddNewDateModal ? 
                <PpcEvaluationAddNewDateFormModal
                    colaboratorId={colaboratorId}
                    modelPPCId={modelPPCId}
                    onCancel={() => setshowAddNewDateModal(false)}
                    onAfterOperation={()=> {
                        setshowAddNewDateModal(false);
                        loadData();
                    }}>
                </PpcEvaluationAddNewDateFormModal> : <></>
            }    

            {
                showDeleteDateModal ? 
                <PpcEvaluationDeleteDateFormModal
                    colaboratorId={colaboratorId}
                    modelPPCId={modelPPCId}
                    onCancel={() => setShowDeleteDateModal(false)}
                    onAfterOperation={()=> {
                        setShowDeleteDateModal(false);
                        loadData();
                    }}>
                </PpcEvaluationDeleteDateFormModal> : <></>
            }   
        </>
    )
}

export default PpcEvaluationForm;