import { App } from 'antd';
import React from 'react';
import { createContext } from "react";

export const MessageContext = createContext();

export const MessageContextProvider = ({children}) => {

    const { message } = App.useApp();
    const showSuccess = (msg) => message.success(msg);

    const showError = (error, defaultMsg) => 
    {
        console.error(error);
        error?.response.data?.errors.length ? 
        error?.response.data?.errors.forEach(currentError => message.error(currentError)) : 
        message.error(defaultMsg);
    }

    return (
        <MessageContext.Provider value={{ 
                showSuccess,
                showError
            }}>
            { children }
        </MessageContext.Provider>
    )
}