import { http } from '.';

const UserService = { 
    get : (filter, page, size) => http.get(`user?search=${filter.search}&page=${page}&pageSize=${size}`),
    create : (model) => http.post(`user`, model),
    update : (unitMeasureId, model) => http.put(`user/${unitMeasureId}`, model),
    delete : (unitMeasureId) => http.delete(`user/${unitMeasureId}`)
}

export default UserService;
