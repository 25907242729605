import { Flex, Tag, Typography } from "antd";
import { AppDivider } from "../../../components";

const PpcColaboratorTasksSummary = ({periodSummary, summary}) => {

    return (
        <>
            <AppDivider light>Resumo Mensal</AppDivider>  
            <Flex align="flex-start" justify="space-between" style={{paddingTop: 0, padding: '8px'}}>
                {
                    periodSummary.map((currentSummary, index) => {
                        return(
                        <Flex align="start" vertical key={index} >                                                    
                            <Typography.Text type="default" align="start"><strong>Semana {currentSummary.period}</strong></Typography.Text>
                            <Typography.Text type="secondary" align="start"><strong>Completadas: </strong> {currentSummary.totalDone}</Typography.Text>
                            <Typography.Text type="secondary" align="start"><strong>Não Completadas: </strong> {currentSummary.totalUndone}</Typography.Text>
                            <Typography.Text type="secondary" align="start"><strong>Total: </strong> {currentSummary.total}</Typography.Text>
                            <Typography.Text type="secondary" align="start"><strong>% Atingindo: </strong>  
                                <Tag color={currentSummary.average > 50 ? 'green' : 'red'} >{currentSummary.average}</Tag>
                            </Typography.Text>
                        </Flex>)
                    })
                }
                <Flex align="start" vertical>   
                    <Typography.Text type="default" align="start"><strong>Resumo Mensal</strong></Typography.Text>
                    <Typography.Text type="secondary" align="start"><strong>Completadas: </strong> {summary.totalDone}</Typography.Text>
                    <Typography.Text type="secondary" align="start"><strong>Não Completadas: </strong> {summary.totalUndone}</Typography.Text>
                    <Typography.Text type="secondary" align="start"><strong>Total: </strong> {summary.total}</Typography.Text>
                    <Typography.Text type="secondary" align="start"><strong>% Atingindo: </strong>                         
                        <Tag color={summary.average > 50 ? 'green' : 'red'} >{summary.average}</Tag>
                    </Typography.Text>
                </Flex>                                         
            </Flex>   
        </>        
    )
}

export default PpcColaboratorTasksSummary;