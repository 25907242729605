import React, { useContext } from "react"
import { Flex, Image, Typography  } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";


const ReportHeader = ({name, subTitle}) => 
{
    const { getJwtKeyValue } = useContext(AuthContext);

    const formatCurrentDate = () => {
        const date = new Date().toISOString().split('T')[0];
        const splittedDate = date.split('-');

        return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
    }

    return (
        <Flex justify="space-between">
            <Image 
                style={{ align: 'start'}} 
                src="../logo.png" 
                width={200} 
                height={64}>                        
            </Image>
            
            <Flex vertical >
                <Typography.Title style={{lineHeight: 0, textAlign: 'center'}} level={3}>{name}</Typography.Title>
                { subTitle && <Typography.Text type="secondary" style={{textAlign: 'center'}}>{subTitle}</Typography.Text> }
            </Flex>
            <Flex vertical style={{paddingTop: '12px'}}>
                <Typography.Text>Usuário: {getJwtKeyValue('name')}</Typography.Text>
                <Typography.Text>Data: {formatCurrentDate()}</Typography.Text>
            </Flex>
    </Flex>
    )
}

export default ReportHeader;