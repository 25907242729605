import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { AuthService } from '../services';
import Cookies from 'js-cookie';
import { MessageContext } from './MessageContext'

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [token, setToken] = useState("");
    const [ isLogged, setIsLogged ] = useState(false);
    const navigate = useNavigate();
    const { showError } = useContext(MessageContext)

    const isLoginPath = () => window.location.pathname === '/';

    useEffect(() => {
        const token = Cookies.get(process.env.REACT_APP_COOKIE_TOKEN);
        if (token)
        {
            setToken(token);
            setIsLogged(true);

            if (isLoginPath()) navigate('/colaborators');

            return;
        }
        else logoff();

    },     
    // eslint-disable-next-line    
    []);

    const getJwtKeyValue = (key) => 
    {
        try 
        {
            const [, payloadBase64] = token.split('.');
            const payload = JSON.parse(atob(payloadBase64));
            return payload[key];
        } catch (error) {
            console.error('Invalid JWT or key not found:', error);
            return null;
        }
    }

    async function login (login, password)
    {
        try 
        {
            const response = await AuthService.login(login, password);
            const token = response.data.value;    
            
            setToken(token);
            setIsLogged(true);
            Cookies.set(process.env.REACT_APP_COOKIE_TOKEN, token, {expires: 1 });     
            navigate('/colaborators');
        } 
        catch (er)
        {
            showError(er, "Ocorreu um problema para efetuar o login");
        }
    }
    
    function logoff() {
        setToken('');        
        setIsLogged(false);
        Cookies.remove(process.env.REACT_APP_COOKIE_TOKEN);
        navigate('/');
    }

    return (
        <AuthContext.Provider value={{ 
                token, 
                isLogged,
                isAdmin : getJwtKeyValue('type') === 'Admin',
                login,
                logoff,
                getJwtKeyValue
            }}>
            { children }
        </AuthContext.Provider>
    )
}