import { Tag } from "antd";
import { AppTable } from "../../../components";
import { useEffect, useState } from "react";

const PpcTaskTable = ({tasks}) => {
    const [ data, setData] = useState([]);
    const columns = [
        {
            title: 'Obra',
            dataIndex: 'constructor',
            key: 'constructor',
            width: 150,
            render: (_) => `${_.name}`
        },
        {
            title: 'Atividade',
            dataIndex: 'task',
            key: 'task',
            width: 150,
            render: (_) => `${_.name}`
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            width: 150
        },  
        {
            title: 'Mês ano',
            dataIndex: 'monthAndYear',
            key: 'monthAndYear',
            width: 70,
        },   
        {
            title: 'Semana',
            dataIndex: 'period',
            key: 'period',
            width: 50,
        },  
        {
            title: 'Planejado',
            dataIndex: 'goalValue',
            key: 'goalValue',
            width: 50,
            render: (_, record) => `${_} ${record.task.unitMeasure.name}`
        },         
        {
            title: 'Realizado',
            dataIndex: 'doneValue',
            key: 'doneValue',
            width: 50,
            render: (value, record) => `${value  == null ? 0 : value} ${record.task.unitMeasure.name}`
        },        
        {
            title: 'Atingido',
            dataIndex: 'goalPercentage',
            key: 'goalPercentage',    
            width: 50,        
            render: (goalPercentage) => <Tag color={goalPercentage < 100 ? "red" : "green"}>{goalPercentage > 100 ? "+ 100 %" : `${goalPercentage == null ? 0 : goalPercentage} %`}</Tag>},
        {
            title: 'Justificativa',
            dataIndex: 'justification',
            key: 'justification',
            width: 150,
            render: (_) => _ && `${_?.name}`
        }
    ];

    useEffect(() => {
        tasks.length ? setData(tasks) : setData([]);
    }, [tasks])

    return (<AppTable  
        bordered    
        rowKey={record => record.id}
        columns={columns} 
        data={data}
        disabledPagination/>)
}

export default PpcTaskTable;