import React, { useContext, useState } from "react"
import { Modal, Form, Row, DatePicker } from 'antd';
import { AppFormItem,  } from "../../../components"
import { CancelIcon, SaveIcon } from "../../../components/Icons";
import { PPCEvaluationService } from "../../../services";
import { ConfirmModalContext } from "../../../contexts/ConfirmModalContext";
import { MessageContext } from "../../../contexts/MessageContext";

const PpcEvaluationDeleteDateFormModal = ({ colaboratorId, modelPPCId, onCancel, onAfterOperation, ...rest}) => 
{
    const [ loading, setLoading ] = useState(false);
    const [form] = Form.useForm();
    const { executeIfConfirm } = useContext(ConfirmModalContext);    
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => 
    {
        executeIfConfirm("Atenção", "Tem certeza que deseja excluir o dia do ppc ?", () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                await PPCEvaluationService.delete(colaboratorId, values.date);            
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
            }
            finally
            {                
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        }));
    }

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    return (        
        <Modal 
            title="Remover Dia" 
            open 
            width={235}
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_ppc_evaluation_delete_date"
                    initialValues={ { date : null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="date"
                            label="Informe a data" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <DatePicker size="small" placeholder="Selecione uma data"></DatePicker>
                        </AppFormItem>                      
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default PpcEvaluationDeleteDateFormModal;