import React, { useContext, useEffect, useState } from "react"
import { AppDivider, EmptyState, Loading, PageHeader } from '../../components';
import { ReportServce } from "../../services";
import { useSearchParams } from "react-router-dom";
import { PcpWeekSummary, PpcColaboratorData, PpcTaskTable, ReportHeader } from "./components";
import "./components/report.css"
import { MessageContext } from "../../contexts/MessageContext";
import { PpcEvaluationTable } from "../ppc/components";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Divider, Row, Typography } from "antd";

const PpcMonthReportPage = () => 
{
    const [ loading, setLoading ] = useState (false);
    const [ data, setData ] = useState ([]);
    const [ searchParams ] = useSearchParams();
    const { showError } = useContext(MessageContext);
    const { isAdmin } = useContext(AuthContext);

    const loadData = async () => {

        try 
        {
            setLoading(true);  
            const filterParams = searchParams.get('params');
            const filterObject = JSON.parse(filterParams); 
            const { data } = await ReportServce.colaboratorPogress({
                ...filterObject,
                monthAndYearStart : filterObject.monthAndYear,
                monthAndYearEnd: filterObject.monthAndYear,
                toPayment : isAdmin
            });
            console.log(data.value);
            setData(data.value);
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para carregar os dados do relatório');
        }
        finally{
           setLoading(false)
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <PageHeader>
                <ReportHeader 
                    name="PPC Mensal do Colaborador"
                    subTitle="Relatório de gerenciamento do PPC mensal de um colaborador"
                ></ReportHeader>
            </PageHeader>
            {
                loading ? 
                <Loading/>
                :  data && data?.months?.length ?                 
                <>
                    <PpcColaboratorData 
                        job={data.months[0].job} 
                        colaborator={data.colaborator}
                        modelPPC={data.months[0].modelPPC}
                        toPayment={isAdmin}
                        status={data.months[0].status}
                        monthAndYear={data.months[0].monthAndYear}
                    ></PpcColaboratorData>

                    <AppDivider light>Avaliações</AppDivider>  
                    <PpcEvaluationTable 
                        disabled
                        style={{padding: '8px'}}
                        loading={loading} 
                        data={data.months[0].evaluation.items}
                        daysInTheMonth={data.months[0].evaluation.daysInTheMonth}
                        bordered></PpcEvaluationTable>     

                    {
                        data.months[0].job.percentageTasks > 0 ? 
                        <>
                            <AppDivider light>Planejamento de Curto Prazo</AppDivider>    
                            <PpcTaskTable style={{padding: '8px'}} tasks={data.months[0].tasks}></PpcTaskTable>  
                        </> : 
                        <></>
                    }  
                    <PcpWeekSummary                                          
                        job={data.months[0].job} 
                        colaborator={data.colaborator}
                        periodSummary={data.months[0].periodSummary}
                        summary={data.months[0].summary}
                        toPayment={isAdmin}
                    ></PcpWeekSummary>
                    
                    <AppDivider light>Assinaturas</AppDivider>  

                    {
                    data.months[0].status === 1 &&                    
                        <Row style={{paddingTop: 50}}>
                            <Col span={12} style={{ textAlign: 'center', paddingRight: '10px', paddingLeft: '10px' }}>
                                <Divider style={{ borderColor: '#6a6a6a' }}/>
                                <Typography.Text align="center">{data.months[0].approveUser?.name}</Typography.Text>                        
                            </Col>
                            <Col span={12} style={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Divider style={{ borderColor: '#6a6a6a' }}/>                
                                <Typography>{data.colaborator.name}</Typography>
                            </Col>
                        </Row>
                    }
                </>
                : <EmptyState />
            }            
        </>
    )
}

export default PpcMonthReportPage;