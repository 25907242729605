import React from "react"
import {  Empty } from "antd";

const EmptyState = () => 
{

    return (
        <Empty description="Nenhum dado foi localizado" style={{marginTop: 50}}></Empty>
    )
}

export default EmptyState;