import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { 
  CalculatorOutlined, 
  DeploymentUnitOutlined, 
  ExceptionOutlined, 
  FormOutlined, 
  FundOutlined,
  HomeOutlined, 
  LinkOutlined, 
  ReconciliationOutlined,
  StockOutlined,
  UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

const { Sider } = Layout;

const AppSiderbar = () => {
  const [ collapsedSidebar] = useState(false);
  const [currentKey, setCurrentKey ] = useState('1');
  const navigate = useNavigate();

  const menuItems = [    
    {
      key : '0',
      label : 'Cadastros',
      type: 'group',
    },
    {
      key : '1',
      icon: <FormOutlined />,
      label : 'Atividades',
      path : '/tasks'
    },   
    {
      key : '2',
      icon: <UserOutlined/>,
      label : 'Colaboradores',
      path : '/colaborators'
    },     
    {
      key : '3',
      icon: <LinkOutlined />,
      label : 'Cargos',
      path : '/jobs'
    },  
    {
      key : '4',
      icon: <ExceptionOutlined />,
      label : 'Justificativas',
      path : '/justifications'
    },      
    {
      key : '5',
      icon: <HomeOutlined />,
      label : 'Obras',
      path : '/constructors'
    },        
    {
      key : '6',
      icon: <DeploymentUnitOutlined />,
      label : 'Unidade de Medidas',
      path : '/unit-measure '
    },      
    {
      key : '7',
      icon: <ReconciliationOutlined />,
      label : 'Modelos de Avaliações',
      path : '/ppc-models'
    },    
    {
      key : '8',
      icon: <CalculatorOutlined />,
      label : 'PPC',
      path : '/ppcs'
    }, 
    {
      key : '9',
      label : 'Relatórios',
      type: 'group',
      children: []
    }, 
    {
      key : '10',
      icon: <FundOutlined/>,
      label : 'Plano de Curto Prazo',
      path : '/report/filter/ppc-tasks'
    },
    {
      key: '11',
      icon : <StockOutlined />,
      label : 'Evolução do Colaborador',
      path : '/report/filter/ppc-colaborator-progress'
    }
  ];

  const onClickHandler = (item) => {
    setCurrentKey(item.key);
    const clicked = menuItems.find(_item => _item.key === item.key);
    navigate(clicked.path)
  }

  return (
    <Sider 
        trigger={null} 
        collapsible 
        collapsed={ collapsedSidebar } 
        width={250}
        theme="light"
        >        
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={currentKey}
          defaultSelectedKeys={['1']}
          items={menuItems}
          onClick={onClickHandler}
        >
        </Menu>
  </Sider>
  );
};

export default AppSiderbar;