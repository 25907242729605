import React, { useContext, useState } from "react"
import { Modal, Form, Row, Col, Typography } from 'antd';
import { AppButton, AppDivider, AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, CreateIcon, DeleteIcon, SaveIcon } from "../../components/Icons";
import { ModelPPCService } from "../../services";
import { MessageContext } from "../../contexts/MessageContext";

const ModelPPCFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? await ModelPPCService.update(currentRecord.id, values) : await ModelPPCService.create(values);              
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    const isLastItem = (index) => {
        const items =form.getFieldValue('items');
        return items.length === (index + 1);
    } 
    
    return (        
        <Modal 
            title="Cadastro de Modelo de Avaliação" 
            open            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            width={700}
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_model_ppc"
                    initialValues={currentRecord ? {...currentRecord} : { 
                        name : '', 
                        description : '', 
                        discountOfColaborator : false, 
                        status : 1, 
                        items: [{ id : 0, name : '', percentage : 0, discountBonus : false }] }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={18}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Por favor informe o nome da justificativa' }]}
                            >
                            <AppInput size="small" placeholder="Informe o nome da justificativa"/>
                        </AppFormItem>
                        <AppFormItem
                            span={6}
                            name="status"
                            label="Status">
                            <AppSelect disabled={! currentRecord} size="small" options={[ 
                                { value: 1, label: "Aberto" }, 
                                { value: 2, label: "Aprovado" }, 
                                { value: 0, label: "Inativo" }]}>
                            </AppSelect>
                        </AppFormItem>         
                    </Row>             
                    <AppDivider>Avaliações</AppDivider>
                    <Row>
                        <Col span={16}>
                            <Typography>Nome</Typography>
                        </Col>
                        <Col span={3}>
                            <Typography>Divisão %</Typography>
                        </Col>
                        <Col span={5}>
                            <Typography>Disconta Premiação</Typography>
                        </Col>
                    </Row>
                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name }, index) => (
                                <Row key={key}>
                                    <AppFormItem                   
                                        style={{marginTop: 0, marginBottom : 0}}
                                        span={16}
                                        name={[name, "name"]}
                                        rules={[{ required: true, message: "Nome obrigatório!" }]}
                                        >
                                        <AppInput placeholder="Nome" />
                                    </AppFormItem>
                                    <AppFormItem                                        
                                        style={{marginTop: 0, marginBottom : 0}}
                                        span={3}
                                        name={[name, "percentage"]}
                                        rules={[
                                            { required: true, message: "Divisão % obrigatória!" },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: "Somente números!",
                                            },
                                        ]}
                                        >
                                        <AppInput placeholder="Divisão %" />
                                    </AppFormItem>
                                    <AppFormItem                                        
                                        style={{marginTop: 0, marginBottom : 0}}
                                        span={4}
                                        name={[name, "discountBonus"]}
                                        >
                                        <AppSelect options={[
                                            { label : 'Sim', value: true }, 
                                            { label : 'Não', value: false }
                                        ]} />
                                    </AppFormItem>
                                    <AppFormItem 
                                        style={{marginTop: 0, marginBottom : 0}}
                                        span={1}>
                                        {
                                            isLastItem(index) ?
                                            <AppButton onClick={() => add({ id : 0, name : '', percentage : null })} icon={<CreateIcon/>}></AppButton> :
                                            <AppButton color="danger" onClick={() => remove(index)} icon={<DeleteIcon/>}></AppButton>
                                        }                                        
                                    </AppFormItem>
                                </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>                                
        </Modal>       
    )
}

export default ModelPPCFormModal;