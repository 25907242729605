import React, { useContext, useState } from "react"
import { Modal, Form, message, Row, Input } from 'antd';
import { AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { UserService } from "../../services";
import { MessageContext } from "../../contexts/MessageContext";

const UserFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [ loading, setLoading ] = useState(false);
    const [form] = Form.useForm();
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? await UserService.update(currentRecord.id, {
                    name :values.name,
                    type : values.type  
                }) : 
                await UserService.create({
                    name :values.name,
                    type : values.type,
                    email : values.email,
                    password : values.password  
                });              
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
            }
            finally
            {
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Usuário" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading : loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_user"
                    initialValues={currentRecord ? {...currentRecord} : { name : '' }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={18}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Compo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Nome do usuário"/>
                        </AppFormItem>  

                        <AppFormItem
                            span={6}
                            name="type"
                            label="Tipo" 
                            rules={[{ required: true, message: 'Compo obrigatório' }]}
                            >
                            <AppSelect size="small" placeholder="Selecione" options={[
                                { value: 0, label: "Admin" },
                                { value: 1, label: "Usuário" }
                            ]}/>
                        </AppFormItem>  

                        {
                            
                            ! currentRecord && 
                            <AppFormItem
                                span={12}
                                name="email"
                                label="Email" 
                                rules={[{ required: true, message: 'Compo obrigatório' }]}
                                >
                                <AppInput size="small" placeholder="Email do usuário"/>
                            </AppFormItem>  
                        }    

                        {
                            ! currentRecord && 
                            <AppFormItem
                                span={12}
                                name="password"
                                label="Senha" 
                                rules={[{ required: true, message: 'Compo obrigatório' }]}
                                >
                                <Input.Password size="small" placeholder="Senha do usuário"/>
                            </AppFormItem>  
                        }
                        
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default UserFormModal;