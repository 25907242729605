import { AppDivider,  } from "../../../components";
import { PpcColaboratorTasksSummary, PpcTaskTable } from "./";

const PpcColaboratorTasks = ({colaboratorId, monthAndYearTasks}) => {

    return (
        <>
            {
                monthAndYearTasks.map(_ => {
                    return(
                    <div key={`${colaboratorId}-${_.monthAndYear}`}>
                        <AppDivider light>{ _.monthAndYear }</AppDivider>
                        <PpcTaskTable tasks={_.tasks}></PpcTaskTable>
                        <PpcColaboratorTasksSummary periodSummary={_.periodSummary} summary={_.summary}></PpcColaboratorTasksSummary>
                    </div>)
                })
            }
        </>)
        
}

export default PpcColaboratorTasks;