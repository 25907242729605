import React from "react"
import { Flex, Tag, Typography } from "antd";
import { AppDivider } from "../../../components";

const PcpWeekSummary = ({periodSummary, summary, toPayment}) => 
{
    return (
        <>
            <AppDivider light>Resumo Mensal</AppDivider>  
            <Flex align="flex-start" justify="space-between" style={{paddingTop: 0, padding: '8px'}}>
                {
                    periodSummary.map((currentSummary, index) => {
                        return(
                        <Flex align="start" vertical key={index} >                                                    
                            <Typography.Text type="default" align="start"><strong>Semana {currentSummary.startPeriodDay} á {currentSummary.endPeriodDay}</strong></Typography.Text>
                            <Typography.Text type="secondary" align="start"><strong>Avaliação: </strong> {currentSummary.percentageEvaluation}%</Typography.Text>
                            { toPayment && <Typography.Text type="secondary" align="start"><strong>Avaliação p/ R$: </strong> {currentSummary.percentageEvaluationToPayment}%</Typography.Text> }
                            <Typography.Text type="secondary" align="start"><strong>PCP: </strong> {currentSummary.percentageTasks}%</Typography.Text>
                            <Typography.Text align="start"><strong>Média: </strong><Tag color={currentSummary.averange > 50 ? "green" : "red"}>{currentSummary.averange}%</Tag></Typography.Text>
                            { toPayment && <Typography.Text align="start"><strong>Média p/ R$: </strong><Tag color={currentSummary.averangeToPayment > 50 ? "green" : "red"}>{currentSummary.averangeToPayment}%</Tag></Typography.Text> }
                        </Flex>)
                    })
                }
                <Flex align="start" vertical>                                                    
                    <Typography.Text type="default" align="start"><strong>Total</strong></Typography.Text>
                    <Typography.Text type="secondary" align="start"><strong>Avaliação: </strong> {summary.percentageEvaluation}%</Typography.Text>
                    { toPayment && <Typography.Text type="secondary" align="start"><strong>Avaliação p/ R$: </strong> {summary.percentageEvaluationToPayment}%</Typography.Text> }
                    <Typography.Text type="secondary" align="start"><strong>PCP: </strong> {summary.percentageTasks}%</Typography.Text>
                    <Typography.Text align="start"><strong>Média: </strong><Tag color={summary.averange > 50 ? "green" : "red"}>{summary.averange}%</Tag></Typography.Text>
                    { toPayment && <Typography.Text align="start"><strong>Média p/ R$: </strong><Tag color={summary.averangeToPayment > 50 ? "green" : "red"}>{summary.averangeToPayment}%</Tag></Typography.Text> }
                </Flex>    
                {
                    toPayment &&
                    <Flex align="start" vertical>
                        <Typography.Text type="default" align="start"><strong>Valores p/ Pagamento</strong></Typography.Text>
                        <Typography.Text type="secondary" align="start"><strong>Sálario Base: </strong>R$ {summary.salary}</Typography.Text>
                        <Typography.Text type="secondary" align="start"><strong>Premiação: </strong>R$ {summary.salaryBonus}</Typography.Text>  
                        <Typography.Text align="start"><strong>Total: </strong>R$ {summary.salaryToPayment}</Typography.Text>                                                
                    </Flex>  
                }                                       
            </Flex>          
        </>
    )
}

export default PcpWeekSummary;