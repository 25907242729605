import React, { useState, useContext, useEffect } from "react"
import { PageHeader, AppButton, AppTable, PageFilter, AppFormItem, AppInput } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon, DeleteIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { UnitMeasureService } from "../../services";
import { Form, Row } from "antd";
import UnitMeasureFormModal from "./UnitMeasureFormModal";
import { ConfirmModalContext } from "../../contexts/ConfirmModalContext";
import { MessageContext } from "../../contexts/MessageContext";

const UnitMeasureQueryPage = () => 
{
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const [ totalItems, setTotalItems ] = useState(0);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);
    const [ loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '' })
    const [ page, setPage ] = useState(1);    
    const [form] = Form.useForm();    
    const { executeIfConfirm } = useContext(ConfirmModalContext);
    const { showError } = useContext(MessageContext);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },    
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={() => setSeletectedRecord(record) }><UpdateIcon/>Editar</AppButton>
                    <AppButton variant="outlined" onClick={() => onDeleteHandler(record.id) }><DeleteIcon/></AppButton>
                </>
            )
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await UnitMeasureService.get(filter, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            showError('Ocorreu um problema para consultar as unidade de medidas');
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('Unidade de Medidas', 'Gerenciamento de unidade de medidas');
    }, []);

    useEffect(() => {
        loadData();
    }, [filter, page]);

    useEffect(() => { 
        if (seletectedRecord) setIsOpenFormModal(true) 
    }, 
    [seletectedRecord])

    const onAfterOperationHandler = async () => {
        resetCrud();
        loadData();
    }    
    
    const resetCrud = () => {        
        setSeletectedRecord(null);
        setIsOpenFormModal(false);
    }

    const onFilterHandler = () => form
        .validateFields()
        .then((values) => setFilter(values))
        .catch((info) => console.log('Validate Failed:', info));

    const onDeleteHandler = (id) => {
        executeIfConfirm( "Atenção", "Tem certeza que deseja excluir a unidade de medida ?", async () => 
        {
            try 
            {
                setLoading(true);   
                await UnitMeasureService.delete(id);
                loadData();
            } 
            catch(error) 
            {
                showError(error, 'Ocorreu um problema para executar a operação');
            }
            finally{
               setLoading(false)
            }
        });
    }

    return (
        <>
            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>                    
                    <Form 
                    form={form}
                    layout="vertical"
                    name="form_unit_measure_filter"
                    initialValues={ { ...filter }}
                    >
                        <Row>
                            <AppFormItem span={24} name="search" label="Nome">
                                <AppInput placeholder="Informe o nome da categoria"/>
                            </AppFormItem>    
                        </Row>
                        <AppButton onClick={onFilterHandler}><FilterIcon/> Filtrar</AppButton>
                    </Form>
                </PageFilter>
            </PageHeader>     

             <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/> 
                {
                isOpenFormModal ? 
                <UnitMeasureFormModal 
                    currentRecord={seletectedRecord}
                    onCancel={() => resetCrud() }
                    onAfterOperation={() => onAfterOperationHandler()}>
                </UnitMeasureFormModal>  :<></>  
            }
                       
        </>
    )
}

export default UnitMeasureQueryPage;