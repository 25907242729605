import React, { useState, useContext, useEffect } from "react"
import { PageHeader, AppButton, AppTable, PageFilter, DefaultFilterForm } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon, PrintIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { PPCMonthService } from "../../services";
import { useNavigate } from 'react-router-dom';
import { PpcGenerateFormModal } from "./components";
import { MessageContext } from "../../contexts/MessageContext";

const PpcQueryPage = () => 
{
    const [ loading, setLoading] = useState(false);
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setTitleAndSubTitle } = useContext(LayoutContext);   
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ totalItems, setTotalItems ] = useState(0);    
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '', status : null})
    const [ page, setPage ] = useState(1);    
    const { showError } = useContext(MessageContext);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'colaborator',
            key: 'colaborator_name',            
            render: (_, record) => record.colaborator.name
        },       
        {
            title: 'Documento',
            dataIndex: 'colaborator',
            key: 'colaborator_document',
            render: (_, record) => record.colaborator.document
        },
        {
            title: 'Cargo',
            dataIndex: 'job',
            key: 'job',
            render: (_, record) => record.job.name
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => record.status === 0 ? "Aberto" : 
                                   record.status === 1 ? "Aprovado" : 
                                   record.status === 2 ? "Aprovação Pendente" :
                                   "Reprovado"
        },
        {
            title: 'Mês ano',
            dataIndex: 'monthAndYear',
            key: 'monthAndYear',            
            render: (_, record) => record.monthAndYear ? record.monthAndYear : "Nunca gerado"
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) => 
                <>
                    <AppButton onClick={() => navigate(`/ppc-form?colaboratorId=${record.colaborator.id}&monthAndYear=${record.monthAndYear}`)}>
                        <UpdateIcon/>Editar
                    </AppButton>
                    <AppButton variant="outlined" onClick={() => window.open(`${window.location.origin}/report/ppc-month?params=${JSON.stringify({
                            monthAndYear : record.monthAndYear, 
                            colaboratorId : record.colaborator.id
                        })}`, '_blank')}><PrintIcon/>
                    </AppButton>
                 
                </>
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await PPCMonthService.get(filter, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para consultar os ppc');
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('PPC', 'Gerenciamento de ppc por colaborador');
    }, []);
    
    useEffect(() => {
        loadData();
    }, [filter, page]);

    const onAfterOperationHandler = (value) => 
    {
        setIsOpenFormModal(false);
        navigate(`/ppc-form?colaboratorId=${value.colaboratorId}&monthAndYear=${value.monthAndYear}`) 
    }    

    return (
        <>
            <PageHeader>
                <AppButton onClick={() =>  setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>                    
                    <DefaultFilterForm 
                    initialValues={filter} 
                    onFilter={(values) => setFilter(values)}                    
                    statusOptions={[ 
                        { value: 0, label: "Aberto" }, 
                        { value: 1, label: "Aprovado" },
                        { value: 2, label: "Aprovação Pendente" },                        
                        { value: 3, label: "Reprovado" }]} />
                </PageFilter>
            </PageHeader>              

            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/>   
                {
                isOpenFormModal ? 
                    <PpcGenerateFormModal
                        onCancel={() => setIsOpenFormModal(false) }
                        onAfterOperation={onAfterOperationHandler}>
                    </PpcGenerateFormModal>  :<></>  
                }
        </>
    )
}

export default PpcQueryPage;