import React, { useEffect, useState } from "react";
import { AppTable } from "../../../components";
import { Checkbox, Tag } from "antd";


const PpcEvaluationTable = ({daysInTheMonth, data, loading, checkHandler, bordered, disabled}) => {

    const [columns, setColumns] = useState([]);

    const populateColumns = (daysInTheMonth) =>
        {            
            const _columns = [{
                title: 'Avaliações',
                dataIndex: 'name',
                key: 'name',
                width: 150
            }];
    
            for (let currentDay = 1; currentDay<=daysInTheMonth; currentDay++)
            {
                const currentDayString = currentDay < 10 ? `0${currentDay}` : `${currentDay}`;
                _columns.push({
                    title: currentDayString,
                    dataIndex:  `day${currentDayString}`,
                    key: `day${currentDayString}`,
                    width: 15,
                    align: 'center',
                    render: (currentDay, record) => 
                    <div className="td-without-padding"> {
                        ! currentDay ? <p>-</p> :
                             record.modelPpcItemId ? 
                                <Checkbox 
                                    disabled={disabled}
                                    checked={currentDay.done} 
                                    onChange={({target}) => checkHandler(currentDay.day, target.checked, record.modelPpcItemId)}>                                                    
                                </Checkbox> : 
                                    <Tag color={currentDay.summary <= 50 ? "red" : "green"}>{currentDay.summary}</Tag> 
                    }
                    </div>
                })
            }
    
            _columns.push({
                title: '% Mês',
                dataIndex:  'summary',
                key: 'summary',
                width: 35,
                align: 'right',
                render: (summary) => <Tag color={summary <= 50 ? "red" : "green"}>{summary}</Tag>
            })
            setColumns(_columns);
    }

    function generateBasicUUID() {
        return Math.random();
      }

    useEffect(() => {
        if (data)
            populateColumns(daysInTheMonth)
    }, [daysInTheMonth, data])

    return (
        
        <AppTable     
        bordered={bordered}        
        rowKey={record => record.modelPpcItemId ? record.modelPpcItemId : generateBasicUUID()}
        loading={loading} 
        columns={columns} 
        disabledPagination
        data={data}/>
    )
}

export default PpcEvaluationTable ;