import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
import { AnonymousLayout, LoggedLayout } from '../components';
import { LoginPage } from './auth';
import { AuthContext } from '../contexts/AuthContext';
import { ColaboratorQueryPage } from './colaborator'
import { ConstructorQueryPage } from './constructor'
import { JobQueryPage } from './job'
import { PpcQueryPage, PpcFormPage } from './ppc'
import { ModelPPCQueryPage } from './model-ppc'
import {JustificationQueryPage} from './justification';
import { UnitMeasureQueryPage } from './unitMeasure';
import { TasksQueryPage } from './tasks';
import { 
  PpcColaboratorProgressFilterReportPage,
  PpcColaboratorProgressReportPage, 
  PpcTaskFilterReportPage, 
  PpcTaskReportPage, 
  PpcMonthReportPage } from './reports';
import { UserQueryPage } from './user';

const AppRoutes = () => {

  const { isLogged }  = useContext(AuthContext);

  return (    
    <>
      { isLogged  ? 
        <LoggedLayout>
          <Routes>               
            <Route path='/users'element={<UserQueryPage/>} />
            <Route path="/colaborators" element={<ColaboratorQueryPage />} />      
            <Route path="/constructors" element={<ConstructorQueryPage />} />     
            <Route path="/justifications" element={<JustificationQueryPage />} />     
            <Route path="/jobs" element={<JobQueryPage />} />             
            <Route path="/ppcs" element={<PpcQueryPage />} />            
            <Route path="/ppc-models" element={<ModelPPCQueryPage />} />     
            <Route path="/ppc-form" element={<PpcFormPage />} />   
            <Route path='/unit-measure' element={<UnitMeasureQueryPage/>}/>       
            <Route path='/tasks'element={<TasksQueryPage/>} />   
            <Route path='/report/filter/ppc-tasks'element={<PpcTaskFilterReportPage/>} />   
            <Route path='/report/ppc-tasks'element={<PpcTaskReportPage/>} />  
            <Route path='/report/filter/ppc-colaborator-progress'element={<PpcColaboratorProgressFilterReportPage/>} />  
            <Route path='/report/ppc-colaborator-progress'element={<PpcColaboratorProgressReportPage/>} />  
            <Route path='/report/ppc-month'element={<PpcMonthReportPage/>} />  
          </Routes>
        </LoggedLayout> :
        <AnonymousLayout>
          <Routes>  
            <Route path="/" element={<LoginPage />} />                
          </Routes>
        </AnonymousLayout> 
      }
    </>)
}

export default AppRoutes