import React, { useContext, useEffect } from "react"
import { AppSelect, AppButton, AppFormItem, AppInput } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { DatePicker, Form, Row } from "antd";
import { ColaboratorSelect } from "../colaborator/components";
import { ConstructorSelect }  from "../constructor/components";
import { JustificationSelect } from "../justification/components";
import { FilterIcon } from "../../components/Icons";
import { TasksSelect } from "../tasks/components";
import { useNavigate } from "react-router";

const PpcTaskFilterReportPage = () => 
{
    const { setTitleAndSubTitle } = useContext(LayoutContext);

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const onFilterHandler = () => form
        .validateFields()
        .then((values) => window.open(`${window.location.origin}/report/ppc-tasks?params=${JSON.stringify(values)}`, '_blank'))
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    useEffect(() => {
        setTitleAndSubTitle('Plano de Curto Prazo', 'Filtro do relatório do plano de curto prazo');
    }, []);

    return (
        <Form 
        form={form}
        layout="vertical"
        name="form_filter_ppc_task_report"
        initialValues={{ 
            search : null, 
            colaboratorId : null,
            taskId : null,
            constructorId : null,
            justificationId : null,
            startDate : null,
            endDate : null,
            period : null
        }}>
        <Row>

                <AppFormItem
                    span={4}
                    name="startDate"
                    label="P. inicial"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <DatePicker size="small" format="MM-YYYY" picker="month" style={{ width: "100%"}} placeholder="selecione ano e mês"/>
                </AppFormItem>

                <AppFormItem
                    span={4}
                    name="endDate"
                    label="P. final"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <DatePicker size="small" format="MM-YYYY" style={{ width: "100%"}} picker="month" placeholder="selecione ano e mês"/>
                </AppFormItem>

                <AppFormItem
                    span={16}
                    name="search"
                    label="Descrição" >
                    <AppInput size="small" placeholder="Informe a descrição da atividade"/>
                </AppFormItem>

                <AppFormItem
                    span={12}
                    name="colaboratorId"
                    label="Colaborador">
                    <ColaboratorSelect placeholder="Selecione o colaborador"></ColaboratorSelect>
                </AppFormItem>
                
                <AppFormItem
                    span={12}
                    name="constructorId"
                    label="Obra">
                    <ConstructorSelect placeholder="Selecione a obra"></ConstructorSelect>
                </AppFormItem>

                <AppFormItem
                    span={12}
                    name="taskId"
                    label="Atividade">
                    <TasksSelect placeholder="Selecione a atividade"></TasksSelect>
                </AppFormItem>

                <AppFormItem
                    span={12}
                    name="justificationId"
                    label="Justificativa">
                    <JustificationSelect placeholder="Selecione a justificativa"></JustificationSelect>
                </AppFormItem>

                <AppFormItem
                    span={4}
                    name="period"
                    label="Semana"
                    >
                    <AppSelect 
                        size="small" 
                        allowClear
                        placeholder="Selecione a semana"
                        options={[ 
                        { value: 1, label: "1" },
                        { value: 2, label: "2" }, 
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" }
                        ]}>
                    </AppSelect>
                </AppFormItem>     
                        
            </Row>   
            <AppButton onClick={onFilterHandler}><FilterIcon/> Filtrar</AppButton> 
        </Form>
    )
}

export default PpcTaskFilterReportPage;