import React, { useContext, useEffect, useState } from "react"
import { AppDivider, EmptyState, Loading, PageHeader } from '../../components';
import { ReportServce } from "../../services";
import { useSearchParams } from "react-router-dom";
import { MonthAndYearHelper } from "../../helpers";
import { PcpWeekSummary, PpcColaboratorData, PpcTaskTable, ReportHeader } from "./components";
import "./components/report.css"
import { MessageContext } from "../../contexts/MessageContext";
import { Collapse } from "antd";
import { PpcEvaluationTable } from "../ppc/components";

const PpcColaboratorProgressReportPage = () => 
{
    const [ loading, setLoading ] = useState (false);
    const [ data, setData ] = useState ([]);
    const [ searchParams ] = useSearchParams();
    const { showError } = useContext(MessageContext);
    const [ filters, setFilters] = useState(null)

    const loadData = async () => {

        try 
        {
            setLoading(true);  
            const filterParams = searchParams.get('params');
            const filterObject = JSON.parse(filterParams); 
            setFilters(filterObject)
            const { data } = await ReportServce.colaboratorPogress({
                ...filterObject,
                monthAndYearStart : MonthAndYearHelper.toMonthAndYear(filterObject.startDate),
                monthAndYearEnd: MonthAndYearHelper.toMonthAndYear(filterObject.endDate)
            });
            setData(data.value);
        } 
        catch(error) 
        {
            showError(error, 'Ocorreu um problema para carregar os dados do relatório');
        }
        finally{
           setLoading(false)
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <PageHeader>
                <ReportHeader 
                    name="Evolução do Colaborador"
                    subTitle="Relatório de gerenciamento da evolução do colaborador"
                ></ReportHeader>
            </PageHeader>
            {
                loading ? 
                <Loading/>
                : data ? 
                <>
                    {
                        data.months?.length ? data.months.map(_ => {
                            return (
                                <Collapse
                                    style={{marginBottom: '8px'}}
                                    expandIconPosition="end"
                                    collapsible="header"
                                    size="small"
                                    defaultActiveKey={[ _.monthAndYear ]}
                                    items={[
                                    {
                                        key: _.monthAndYear,
                                        label: _.monthAndYear,
                                        children: 
                                        <>
                                            <PpcColaboratorData 
                                                job={_.job} 
                                                colaborator={data.colaborator}
                                                modelPPC={_.modelPPC}
                                                toPayment={filters?.toPayment}
                                            ></PpcColaboratorData>

                                            <AppDivider light>Avaliações</AppDivider>  
                                            <PpcEvaluationTable 
                                                disabled
                                                style={{padding: '8px'}}
                                                loading={loading} 
                                                data={_.evaluation.items}
                                                daysInTheMonth={_.evaluation.daysInTheMonth}
                                                bordered></PpcEvaluationTable>     

                                            {
                                                _.job.percentageTasks > 0 ? 
                                                <>
                                                    <AppDivider light>Planejamento de Curto Prazo</AppDivider>    
                                                    <PpcTaskTable style={{padding: '8px'}} tasks={_.tasks}></PpcTaskTable>  
                                                </> : 
                                                <></>
                                            }  

                                            <PcpWeekSummary                                           
                                                periodSummary={_.periodSummary} 
                                                summary={_.summary}
                                                toPayment={filters?.toPayment}
                                            ></PcpWeekSummary>
                                        </>
                                        }
                                    ]}
                                >                          
                                </Collapse>)
                        }) : <EmptyState />
                    }
                </>
                : <EmptyState />

            }            
        </>
    )
}

export default PpcColaboratorProgressReportPage;