import React, { useContext, useState } from "react"
import { Modal, Form, Row } from 'antd';
import { AppFormItem, AppInput, AppInputNumber, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { JobService } from "../../services";
import { MessageContext } from "../../contexts/MessageContext";

const JobFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {   
                setLoading(true);        
                currentRecord ? await JobService.update(currentRecord.id, values) : await JobService.create(values);              
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
                console.log(error);    
            }
            finally{
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Cargos" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_job"
                    initialValues={currentRecord ? {...currentRecord} : { name : '', status : 1, salary: 0, salaryBonus : 0 }}
                    {...rest}>
                    <Row>

                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Informe o nome do cargo"/>
                        </AppFormItem>

                        <AppFormItem
                            span={6}
                            name="salary"
                            label="Salário" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInputNumber />
                        </AppFormItem>

                        <AppFormItem
                            span={6}
                            name="salaryBonus"
                            label="Premiação" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInputNumber />
                        </AppFormItem>

                        <AppFormItem
                            span={6}
                            name="percentagePPC"
                            label="% Avaliação"
                            rules={[
                                { required: true, message: "% PPC Avaliação obrigatória!" },
                                {
                                    pattern: /^[0-9]+$/,
                                    message: "Somente números!",
                                },
                            ]}
                            >
                            <AppInput placeholder="Digite o percentual" />
                        </AppFormItem>

                        <AppFormItem
                            span={6}
                            name="percentageTasks"
                            label="% Pcp"
                            rules={[
                                { required: true, message: "% de Pcp obrigatória!" },
                                {
                                    pattern: /^[0-9]+$/,
                                    message: "Somente números!",
                                },
                            ]}
                            >
                            <AppInput placeholder="Digite o percentual" />
                        </AppFormItem>
                        
                        <AppFormItem
                            span={6}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>  

                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default JobFormModal;