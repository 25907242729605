import React, { useContext, useState } from "react"
import { Modal, Form, Row } from 'antd';
import { AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { TasksService } from "../../services";
import { UnitMeasureSelect } from "../unitMeasure/components";
import { MessageContext } from "../../contexts/MessageContext";

const TasksFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const { showError, showSuccess } = useContext(MessageContext);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ?
                await TasksService.update(currentRecord.id, {
                        name : values.name,
                        unitMeasureId : values.unitMeasureId,
                        status : values.status
                }) : 
                await TasksService.create({
                    name : values.name,
                    unitMeasureId : values.unitMeasureId
                });              
                
                form.resetFields();
                showSuccess('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Atividades" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_tasks"
                    initialValues={currentRecord ? 
                        { name : currentRecord.name, status : currentRecord.status, unitMeasureId : currentRecord.unitMeasure.id} : 
                        { name : '', status : 1, unitMeasureId : null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Nome da atividade"/>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="unitMeasureId"
                            label="Un. Medida" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <UnitMeasureSelect currentRecord={currentRecord?.unitMeasure} placeholder="Selecione"/>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>           
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default TasksFormModal;