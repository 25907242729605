import { http } from '.';

const ReportServce = {
    tasks: (filter) =>
    {
        const params = new URLSearchParams({   
            search : filter.search ? filter.search : "",
            taskId : filter.taskId ? filter.taskId : "",
            colaboratorId : filter.colaboratorId ? filter.colaboratorId : "",
            monthAndYearStart : filter.monthAndYearStart,
            monthAndYearEnd: filter.monthAndYearEnd,
            period : filter.period ? filter.period : "",
            constructorId : filter.constructorId ? filter.constructorId : "",
            justificationId : filter.justificationId ? filter.justificationId : ""
            }).toString();
        return http.get(`report/tasks?${params}`);
    },  
    colaboratorPogress: (filter) =>
    {        
        const params = new URLSearchParams({   
            colaboratorId : filter.colaboratorId ? filter.colaboratorId : "",
            monthAndYearStart : filter.monthAndYearStart,
            monthAndYearEnd: filter.monthAndYearEnd,
            toPayment : filter.toPayment
            }).toString();
        return http.get(`report/colaborator-progress?${params}`);
    }  
}

export default ReportServce;

