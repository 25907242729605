import React from "react";
import { ConstructorService  } from "../../../services";
import { PaginatedSelect } from "../../../components";

const ConstructorSelect = ({currentRecord, ...rest}) => {
  return (
    <PaginatedSelect
    currentRecord={currentRecord ? {id : currentRecord.id, label : currentRecord.name} : null} 
        apiCallback={ConstructorService.get}         
        statusFilter={1}
        {...rest}></PaginatedSelect>
  );
};

export default ConstructorSelect;
