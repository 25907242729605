import React, { useContext, useState } from "react"
import { Modal, Form, Input, message } from 'antd';
import { AppFormItem } from "./"
import { CancelIcon, SaveIcon } from "./Icons";
import { AuthService } from "../services";
import { MessageContext } from "../contexts/MessageContext";

const ChangePasswordModal = ({isOpen, onCancel, onAfterOperation, ...rest}) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const { showError, showSuccess } = useContext(MessageContext);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {         
                setIsLoading(true);

                await AuthService.changePassword(values.currentPassword, values.newPassword);          
                
                form.resetFields();
                showSuccess('A senha foi atualizada com sucesso');               
                
                onAfterOperation();   
            } 
            catch (error) {
                showError(error, 'Ocorreu um problema para realizar operação');
            }
            finally
            {
                setIsLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Altere sua Senha" 
            open={isOpen}            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: isLoading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_change_password"
                    initialValues={{ currentPassword : '', newPassword: '' }}
                    {...rest}>

                    <AppFormItem
                        name="currentPassword"
                        label="Senha atual" 
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                        <Input.Password size="small" placeholder="Informe sua senha atual"/>
                    </AppFormItem>

                    <AppFormItem
                        name="newPassword"
                        label="Nova senha" 
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                        <Input.Password size="small" placeholder="Informe sua nova senha"/>
                    </AppFormItem>
                </Form>
        </Modal>
       
    )
}

export default ChangePasswordModal;