import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { App, ConfigProvider } from 'antd';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { AuthContextProvider } from './contexts/AuthContext';
import { ConfirmModalContextProvider } from './contexts/ConfirmModalContext';
import { MessageContextProvider } from './contexts/MessageContext';
import { ConfirmModal } from './components';
import AppRoutes from './pages/AppRoutes';
import ptBR from 'antd/es/locale/pt_BR';
import dayjs from 'dayjs';
import './App.css';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

function MyApp() {
  return (
    <BrowserRouter>
      <App>
        <ConfigProvider locale={ptBR}>
          <MessageContextProvider>
            <AuthContextProvider>
              <LayoutContextProvider>
                <ConfirmModalContextProvider>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#ff6600',
                      },
                    }}>
                      <AppRoutes></AppRoutes>
                      <ConfirmModal></ConfirmModal>
                  </ConfigProvider>
                </ConfirmModalContextProvider>
              </LayoutContextProvider>
            </AuthContextProvider>
          </MessageContextProvider>
        </ConfigProvider>
      </App>
    </BrowserRouter>
  );
}

export default MyApp;
